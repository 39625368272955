<template>
  <v-dialog
    v-model="deviceDetailDialog"
    max-width="1840"
    content-class="device_detail_dialog"
    persistent
  >
    <v-card id="device_detail_wp" class="dialog_detail_content">
      <v-card-title>
        <div class="close_button" title="Close" @click="closeDialog">
          <span>
            <i class="fas fa-times"></i>
          </span>
        </div>
        <button
          class="dialog-default-button dialog-button-add-size dialog-bg-grey-button"
          style="opacity: 1; cursor: default;"
          :ripple="false"
          type="button"
        >
          DETAIL
        </button>
        <button
          class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-outline-button"
          :ripple="false"
          type="button"
          @click="openDialogHistoryRecord"
        >
          HISTORY RECORD
        </button>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="4" class="basic_info_col">
            <div v-if="deviceDetailData" class="basic_info_wp">
              <div class="status_image">
                <div class="device_status">
                  <p :style="`color: ${SITE_STATUS_COLORS[deviceDetailData.device?.status]}`">{{ deviceDetailData.device?.status ? capitalizeFirstLetter(deviceDetailData.device.status) : '' }}</p>
                </div>
                <div class="device_image">
                  <img :src="devicesImageUrl[deviceDetailData.device?.type]" />
                </div>
              </div>
              <div class="information_card">
                <div class="information_card_header">
                  <div class="row_information_space">
                    <h2 class="information_title">Basic Info</h2>
                    <div class="device_detail_actions">
                      <v-btn 
                        class="information_action_btn" 
                        icon
                      >
                        <img src="@/assets/tables/asset_edit.svg" />
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="information_card_content">
                  <div class="row_information_space">
                    <p class="detail_title">Device Name</p>
                    <p class="detail_content">{{ deviceDetailData.device?.name ? deviceDetailData.device.name : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">SN</p>
                    <p class="detail_content">{{ deviceDetailData.device?.sn ? deviceDetailData.device.sn : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Brand</p>
                    <p class="detail_content">{{ deviceDetailData.device?.brand ? deviceDetailData.device.brand : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Hardware Model</p>
                    <p class="detail_content">{{ deviceDetailData.device?.model ? deviceDetailData.device.model : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Software Version</p>
                    <p class="detail_content">{{ deviceDetailData.device?.software_version ? deviceDetailData.device.software_version : '✕' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Rated Value</p>
                    <p class="detail_content">{{ deviceDetailData.device?.rated_capacity ? deviceDetailData.device.rated_capacity : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Commissioned Date</p>
                    <p class="detail_content">{{ deviceDetailData.device?.date_of_commission ? MOMENT(deviceDetailData.device.date_of_commission, "DD/MM/YYYY") : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Last Maintenance</p>
                    <p class="detail_content">{{ deviceDetailData.device?.last_maintenance ? MOMENT(deviceDetailData.device.last_maintenance, "DD/MM/YYYY") : '−−' }}</p>
                  </div>
                  <div class="row_information_space">
                    <p class="detail_title">Next Maintenance</p>
                    <p class="detail_content">{{ deviceDetailData.device?.next_maintenance ? MOMENT(deviceDetailData.device.next_maintenance, "DD/MM/YYYY") : '(Not scheduled)' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="8" class="more_info_col">
            <div class="more_info_wp">
              <!-- Device evse -->
              <template v-if="deviceDetailData?.device?.type === 'evse'">
                <div class="more_info_tabs">
                  <v-btn-toggle v-model="selectedInfoTabs" @change="changeInfoTabs" mandatory>
                    <v-btn value="connector_1">Connector 1</v-btn>
                    <v-btn value="connector_2">Connector 2</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="more_info_content">
                  <div class="operation_realtime_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Operation & Realtime Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charging State</h3>
                            <p>{{ moreDetailInfoData?.charge_status ? moreDetailInfoData.charge_status : '−−'  }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charging Duration</h3>
                            <p>{{ moreDetailInfoData?.charging_time ? moreDetailInfoData.charging_time : '−−' }} mins</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charging Power</h3>
                            <p>{{ moreDetailInfoData?.power !== null && moreDetailInfoData?.power !== undefined ? moreDetailInfoData.power : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charging Energy</h3>
                            <p>{{ moreDetailInfoData?.energy !== null && moreDetailInfoData?.energy !== undefined ? moreDetailInfoData.energy : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Voltage</h3>
                            <p>{{ moreDetailInfoData?.voltage !== null && moreDetailInfoData?.voltage !== undefined ? moreDetailInfoData.voltage : '−−' }} V</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Current</h3>
                            <p>{{ moreDetailInfoData?.current !== null && moreDetailInfoData?.current !== undefined ? moreDetailInfoData.current : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Next Schedule Start at</h3>
                            <p>{{ moreDetailInfoData?.start_time ? MOMENT(moreDetailInfoData.start_time, "DD/MM/YYYY HH:mm:ss") : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Next Schedule End at</h3>
                            <p>{{ moreDetailInfoData?.end_time ? MOMENT(moreDetailInfoData.end_time, "DD/MM/YYYY HH:mm:ss") : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Next Schedule Setting</h3>
                            <p>15 A</p>
                            <!-- <p>{{ moreDetailInfoData?.reserve_current !== null && moreDetailInfoData?.reserve_current !== undefined ? moreDetailInfoData.reserve_current : '−−' }} A</p> -->
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Configuration Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>EVSE Phase</h3>
                            <p>{{ moreDetailInfoData?.evse_phase ? evsePhaseText[moreDetailInfoData.evse_phase] : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Is Locker</h3>
                            <p>{{ moreDetailInfoData?.is_lock ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Lock Status</h3>
                            <p>{{ moreDetailInfoData?.lock_status ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Plug-and-Charge Status</h3>
                            <p>{{ moreDetailInfoData?.pnc_status ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Load Balance</h3>
                            <p>{{ moreDetailInfoData?.load_balance !== null && moreDetailInfoData?.load_balance !== undefined ? moreDetailInfoData.load_balance : '−−' }} W</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Minimum Current</h3>
                            <p>{{ moreDetailInfoData?.min_current !== null && moreDetailInfoData?.min_current !== undefined ? moreDetailInfoData.min_current : '−−' }} A</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Maximum Current</h3>
                            <p>{{ moreDetailInfoData?.max_current !== null && moreDetailInfoData?.max_current !== undefined ? moreDetailInfoData.max_current : '−−' }} A</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>User Setting Current</h3>
                            <p>{{ moreDetailInfoData?.user_current !== null && moreDetailInfoData?.user_current !== undefined ? moreDetailInfoData.user_current : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </template>
              <!-- End Device evse -->

              <!-- Device pv -->
              <template v-if="deviceDetailData?.device?.type === 'pv'">
                <div class="more_info_tabs">
                  <v-btn-toggle v-model="selectedInfoTabs" @change="changeInfoTabs" mandatory>
                    <v-btn value="pv_string_1">PV String 1</v-btn>
                    <v-btn value="pv_string_2">PV String 2</v-btn>
                    <v-btn value="pv_string_3">PV String 3</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="more_info_content">
                  <div class="operation_realtime_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Operation & Realtime Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Inverter Status</h3>
                            <p>{{ moreDetailInfoData?.inverter_status ? moreDetailInfoData.inverter_status : '−−'  }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Active Power</h3>
                            <p>{{ moreDetailInfoData?.active_power ? moreDetailInfoData.active_power : '−−' }} mins</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Daily Energy</h3>
                            <p>{{ moreDetailInfoData?.daily_active_energy ? moreDetailInfoData.daily_active_energy : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Cumulative Energy</h3>
                            <p>{{ moreDetailInfoData?.daily_reactive_energy ? moreDetailInfoData.daily_reactive_energy : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Reactive Power</h3>
                            <p>{{ moreDetailInfoData?.reactive_power ? moreDetailInfoData.reactive_power : '−−'  }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Power Factor</h3>
                            <p>{{ moreDetailInfoData?.charging_time ? moreDetailInfoData.charging_time : '−−' }} mins</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Internal Temperature</h3>
                            <p>{{ moreDetailInfoData?.power ? moreDetailInfoData.power : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Grid Frequency</h3>
                            <p>{{ moreDetailInfoData?.energy ? moreDetailInfoData.energy : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase A Voltage</h3>
                            <p>{{ moreDetailInfoData?.start_time ? moreDetailInfoData.start_time : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase B Voltage</h3>
                            <p>{{ moreDetailInfoData?.end_time ? moreDetailInfoData.end_time : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase C Voltage</h3>
                            <p>{{ moreDetailInfoData?.reserve_current ? moreDetailInfoData.reserve_current : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase A Current</h3>
                            <p>{{ moreDetailInfoData?.start_time ? moreDetailInfoData.start_time : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase B Current</h3>
                            <p>{{ moreDetailInfoData?.end_time ? moreDetailInfoData.end_time : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase C Current</h3>
                            <p>{{ moreDetailInfoData?.reserve_current ? moreDetailInfoData.reserve_current : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Configuration Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Operation</h3>
                            <p>{{ moreDetailInfoData?.evse_phase ? moreDetailInfoData.evse_phase : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>MPPT Mode</h3>
                            <p>{{ moreDetailInfoData?.is_lock ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Anti-backflow Funtion</h3>
                            <p>{{ moreDetailInfoData?.lock_status ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Active Power Limit</h3>
                            <p>{{ moreDetailInfoData?.pnc_status ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </template>
              <!-- End Device pv -->

              <!-- Device bess -->
              <template v-if="deviceDetailData?.device?.type === 'bess'">
                <div class="more_info_tabs">
                  <v-btn-toggle v-model="selectedInfoTabs" @change="changeInfoTabs" mandatory>
                    <v-btn value="pack_1">Pack 1</v-btn>
                    <v-btn value="pack_2">Pack 2</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="more_info_content">
                  <div class="operation_realtime_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Operation & Realtime Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Battery Status</h3>
                            <p>{{ moreDetailInfoData?.battery_status ? moreDetailInfoData.battery_status : '−−'  }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Output Power</h3>
                            <p>{{ moreDetailInfoData?.charging_time ? moreDetailInfoData.charging_time : '−−' }} mins</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Bus Voltage</h3>
                            <p>{{ moreDetailInfoData?.voltage ? moreDetailInfoData.voltage : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Bus Current</h3>
                            <p>{{ moreDetailInfoData?.current ? moreDetailInfoData.current : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>State-of-Charge</h3>
                            <p>{{ moreDetailInfoData?.soc_percent ? moreDetailInfoData.soc_percent : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>State-of-Health</h3>
                            <p>{{ moreDetailInfoData?.soh_percent ? moreDetailInfoData.soh_percent : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Internal Temperature</h3>
                            <p>{{ moreDetailInfoData?.temperature ? moreDetailInfoData.temperature : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Daily Charge Energy</h3>
                            <p>{{ moreDetailInfoData?.daily_charged_energy ? moreDetailInfoData.daily_charged_energy : '−−'  }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Total Charge Energy</h3>
                            <p>{{ moreDetailInfoData?.charged_energy ? moreDetailInfoData.charged_energy : '−−' }} mins</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Daily Discharge Energy</h3>
                            <p>{{ moreDetailInfoData?.daily_discharged_energy ? moreDetailInfoData.daily_discharged_energy : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Total Discharge Energy</h3>
                            <p>{{ moreDetailInfoData?.discharged_energy ? moreDetailInfoData.discharged_energy : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Configuration Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Operation Mode</h3>
                            <p>{{ moreDetailInfoData?.evse_phase ? moreDetailInfoData.evse_phase : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Protection Mode</h3>
                            <p>{{ moreDetailInfoData?.is_lock ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Minimum SOC Limit</h3>
                            <p>{{ moreDetailInfoData?.lock_status ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Maximum SOC Limit</h3>
                            <p>{{ moreDetailInfoData?.pnc_status ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charge Voltage Limit</h3>
                            <p>{{ moreDetailInfoData?.evse_phase ? moreDetailInfoData.evse_phase : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Charge Current Limit</h3>
                            <p>{{ moreDetailInfoData?.is_lock ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Discharge Voltage Limit</h3>
                            <p>{{ moreDetailInfoData?.lock_status ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Discharge Current Limit</h3>
                            <p>{{ moreDetailInfoData?.pnc_status ? 'True' : 'False' }}</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </template>
              <!-- End Device bess -->

              <!-- Device pm -->
              <template v-if="deviceDetailData?.device?.type === 'pm'">
                <div class="more_info_tabs" style="visibility: hidden;">
                  <v-btn-toggle mandatory>
                    <v-btn>PM 1</v-btn>
                    <v-btn>PM 2</v-btn>
                  </v-btn-toggle>
                </div>
                <div class="more_info_content">
                  <div class="operation_realtime_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Operation & Realtime Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Active Power</h3>
                            <p>{{ moreDetailInfoData?.active_power ? moreDetailInfoData.active_power : '−−'  }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Reactive Power</h3>
                            <p>{{ moreDetailInfoData?.charging_time ? moreDetailInfoData.charging_time : '−−' }} mins</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Power Factor</h3>
                            <p>{{ moreDetailInfoData?.power ? moreDetailInfoData.power : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Grid Frequency</h3>
                            <p>{{ moreDetailInfoData?.grid_frequency ? moreDetailInfoData.grid_frequency : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Import Energy</h3>
                            <p>{{ moreDetailInfoData?.import_energy ? moreDetailInfoData.import_energy : '−−'  }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Export Energy</h3>
                            <p>{{ moreDetailInfoData?.export_energy ? moreDetailInfoData.export_energy : '−−' }} mins</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Active Energy</h3>
                            <p>{{ moreDetailInfoData?.active_energy ? moreDetailInfoData.active_energy : '−−' }} kW</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Net Energy</h3>
                            <p>{{ moreDetailInfoData?.net_energy ? moreDetailInfoData.net_energy : '−−' }} kWh</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase A Voltage</h3>
                            <p>{{ moreDetailInfoData?.start_time ? moreDetailInfoData.start_time : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase B Voltage</h3>
                            <p>{{ moreDetailInfoData?.end_time ? moreDetailInfoData.end_time : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase C Voltage</h3>
                            <p>{{ moreDetailInfoData?.reserve_current ? moreDetailInfoData.reserve_current : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase A Current</h3>
                            <p>{{ moreDetailInfoData?.start_time ? moreDetailInfoData.start_time : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase B Current</h3>
                            <p>{{ moreDetailInfoData?.end_time ? moreDetailInfoData.end_time : '−−' }}</p>
                          </div>
                        </v-col>
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>Phase C Current</h3>
                            <p>{{ moreDetailInfoData?.reserve_current ? moreDetailInfoData.reserve_current : '−−' }} A</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="configuration_info">
                    <div class="more_info_heading">
                      <h2 class="more_info_title">Configuration Info</h2>
                    </div>
                    <div class="more_info_desc">
                      <v-row no-gutters class="more_info_desc_row">
                        <v-col cols="3">
                          <div class="more_info_desc_group">
                            <h3>System Type</h3>
                            <p>{{ moreDetailInfoData?.evse_phase ? moreDetailInfoData.evse_phase : '−−' }}</p>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </template>
              <!-- End Device pm -->
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogHistoryRecord"
      persistent
      content-class="custom-content-modals"
      max-width="756"
    >
      <v-card>
        <v-card-title>History Record</v-card-title>
        <v-card-text class="custom-asset-card v-card__text-history">
          <v-form
            ref="historyRecordForm"
            @submit.prevent="submitHistoryRecord"
            autocomplete="off"
          >
            <v-row no-gutters class="pt-2">
              <v-col cols="12" md="4" class="modal-label">
                <span>Select Report Range</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-select
                  :items="reportRangeTypeList"
                  v-model="reportRange"
                  :rules="reportRangeRules"
                  item-text="name"
                  item-value="key"
                  :class="`${reportRange ? 'custom-hidden-input' : ''}`"
                  required
                  filled
                  append-icon="fas fa-caret-down"
                  placeholder="Select Report Range"
                  :validate-on-blur="true"
                  @change="handleChangeRangeType"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2 checkboxes-row text-light">
              <v-col cols="6" md="6" class="date-picker-table">
                <v-row no-gutters class="date-picker-row">
                  <p class="date-picker-title">From</p>
                  <v-menu
                    ref="historyRecordDateMenu"
                    v-model="historyRecordDateMenu"
                    :close-on-content-click="false"
                    :disabled="!['custom_range'].includes(reportRange)"
                    transition="scale-transition"
                    offset-y
                    max-width="332"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="historyRecordDateTextFrom"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        append-icon="fas fa-caret-down"
                        class="date-picker-text-field"
                        :class="`${!['custom_range'].includes(reportRange) ? 'disabled_input_field' : '' }`"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      flat
                      v-model="historyRecordDateRange"
                      no-title
                      range
                      width="auto"
                      first-day-of-week="0"
                      type="date"
                      :dark="true"
                      class="customize_commissioned_date_picker"
                      :disabled="!['custom_range'].includes(reportRange)"
                      @input="closeHistoryRecordDateMenu"
                    ></v-date-picker>
                  </v-menu>
                </v-row>
              </v-col>
              <v-col cols="6" md="6" class="date-picker-table">
                <v-row no-gutters class="date-picker-row">
                  <p class="date-picker-title">To</p>
                  <v-text-field
                    v-model="historyRecordDateTextTo"
                    readonly
                    append-icon="fas fa-caret-down"
                    class="date-picker-text-field"
                    :class="`${!['custom_range'].includes(reportRange) ? 'disabled_input_field' : '' }`"
                    @click="openHistoryRecordDateMenu"
                  >
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2 history-button-space">
              <v-spacer></v-spacer>
              <v-col cols="12" class="text-center">
                <button
                  class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                  :ripple="false"
                  type="button"
                  @click="closeDialogHistoryRecord"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                  :ripple="false"
                  type="submit"
                >
                  DOWNLOAD
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<style lang="scss" src="@/assets/css/device-detail.scss"></style>
<script>
import { assetService } from "@/services";
import moment from "moment";

import ev_charger from "@/assets/pages/device/ev_charger.png";
import pv_inverter from "@/assets/pages/device/pv_inverter.png";
import bess from "@/assets/pages/device/bess.png";
import smart_meter from "@/assets/pages/device/smart_meter.png";
import eBox from "@/assets/pages/device/eBox.png";

export default {
  props: {
    deviceDetailDialog: {
      default: false
    },
    deviceId: {
      default: null
    },
  },
  async created() {
    await this.openDialogDeviceDetail(this.deviceId);
  },
  computed: {
    historyRecordDateTextFrom: {
      get() {
        if (this.historyRecordDateRange && this.historyRecordDateRange.length > 0) {
          return `${this.historyRecordDateRange[0] ? moment(this.historyRecordDateRange[0]).format("DD/MM/YYYY") : ''}`;
        }
        return null;
      },
      set() {}
    },
    historyRecordDateTextTo: {
      get() {
        if (this.historyRecordDateRange && this.historyRecordDateRange.length > 1) {
          return `${this.historyRecordDateRange[1] ? moment(this.historyRecordDateRange[1]).format("DD/MM/YYYY") : ''}`;
        }
        return null;
      },
      set() {}
    },
  },
  data() {
    return {
      dialogHistoryRecord: false,
      reportRange: "",
      reportRangeText: "",
      historyRecordDateMenu: false,
      historyRecordDateRange: [],
      reportRangeTypeList: [
        {
          key: "all",
          name: "All",
        },
        {
          key: "month_to_date",
          name: "Month To Date",
        },
        {
          key: "year_to_date",
          name: "Year to Date",
        },
        {
          key: "previous_month",
          name: "Previous Month",
        },
        {
          key: "previous_year",
          name: "Previous Year",
        },
        {
          key: "past_30_days",
          name: "Past 30 Days",
        },
        {
          key: "custom_range",
          name: "Custom Range",
        },
      ],
      reportRangeRules: [
        (v) =>!!v || "Report Range is required",
      ],
      selectedInfoTabs: "",
      deviceDetailData: {},
      moreDetailInfoData: null,
      devicesImageUrl: {
        "sc": eBox,
        "pv": pv_inverter,
        "bess": bess,
        "evse": ev_charger,
        "pm": smart_meter,
      },
      dataForm: {
        "device": {
          "connector1": {
            "charge_status": "idle",
            "charging_time": "0:0:0",
            "power": 0,
            "energy": 0,
            "voltage": 236.41,
            "current": 0,
            "start_time": null,
            "end_time": null,
            "reserve_current": 0,
            "evse_phase": "singlephase",
            "is_lock": 0,
            "lock_status": 0,
            "pnc_status": 0,
            "load_balance": 2595,
            "min_current": 6,
            "max_current": 32,
            "user_current": 12
          },
          "connector2": {
            "charge_status": "idle 2",
            "charging_time": "0:0:0",
            "power": 1,
            "energy": 1,
            "voltage": 236.41,
            "current": 1,
            "start_time": null,
            "end_time": null,
            "reserve_current": 1,
            "evse_phase": "singlephase 2",
            "is_lock": 1,
            "lock_status": 1,
            "pnc_status": 1,
            "load_balance": 2596,
            "min_current": 7,
            "max_current": 33,
            "user_current": 13
          },
        }
      },
      evsePhaseText: {
        "singlephase": "Single-phase",
        "threephase": "Three-phase",
      },
    };
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog');
    },
    openDialogHistoryRecord() {
      this.dialogHistoryRecord = true;
    },
    revertHistoryRecord() {
      this.$refs.historyRecordForm.reset();
    },
    closeDialogHistoryRecord() {
      this.revertHistoryRecord();
      this.historyRecordDateRange = [];
      this.dialogHistoryRecord = false;
    },
    openHistoryRecordDateMenu() {
      return this.historyRecordDateMenu = !this.historyRecordDateMenu;
    },
    closeHistoryRecordDateMenu() {
      if(this.historyRecordDateRange && this.historyRecordDateRange[0] && this.historyRecordDateRange[1]){
        this.historyRecordDateMenu = false;
      }else{
        this.historyRecordDateMenu = true;
      }
      return this.historyRecordDateMenu;
    },
    async submitHistoryRecord() {
      // if (this.$refs.historyRecordForm.validate()) {
      //   let siteID = this.$store.getters.siteId ? this.$store.getters.siteId : null;
      //   let maintenance_date = moment.utc(this.nextMaintenanceDate).toISOString();
      //   const data = this.prepareDataMaintenance(
      //     siteID,
      //     maintenance_date,
      //     this.assignInstaller,
      //   );
      //   await assetService
      //     .storeMaintenanceSchedule(data)
      //     .then((res) => {
      //       if (res.status !== 200) throw res;
      //       this.getListAssets();
      //       this.closeDialogMaintenanceSchedule();
      //       this.apiMessage = "Maintenance schedule has been successfully created";
      //       this.apiTitle = "Success";
      //       this.messageDialog = true;
      //     })
      //     .catch((err) => {
      //       let message = "Maintenance schedule cannot be created";
      //       if (err?.response?.data?.message) {
      //         message = err.response.data.message;
      //       }
      //       this.apiMessage = message;
      //       this.apiTitle = "Error";
      //       this.messageDialog = true;
      //     });
      // }
    },
    changeInfoTabs () {
      if (this.deviceDetailData?.device?.type === "evse") {
        if (this.selectedInfoTabs === 'connector_1') {
          this.moreDetailInfoData = this.dataForm?.device?.connector1 || null;
        } else if (this.selectedInfoTabs === 'connector_2') {
          this.moreDetailInfoData = this.dataForm?.device?.connector2 || null;
        } else {
          this.moreDetailInfoData = null;
        }
      } else if (this.deviceDetailData?.device?.type === "pv") {
        return true
      }
      // return "OK";
    },
    async openDialogDeviceDetail(deviceId) {
      if(deviceId) {
        await assetService
          .getAssetDetail(deviceId)
          .then((res) => {
            if (res?.data?.data) {
              const data = res.data.data;
              this.deviceDetailData = {...data};
            }
          })
          .catch();
      }
    },
    handleChangeRangeType () {
      this.historyRecordDateRange = [];
      if (this.reportRange === 'all') {
        console.log('all');
      } else if (this.reportRange === 'month_to_date') {
        this.historyRecordDateRange[0] = moment().startOf('month').format('YYYY-MM-DD');
        this.historyRecordDateRange[1] = moment().format('YYYY-MM-DD');
      } else if (this.reportRange === 'year_to_date') {
        this.historyRecordDateRange[0] = moment().startOf('year').format('YYYY-MM-DD');
        this.historyRecordDateRange[1] = moment().format('YYYY-MM-DD');
      } else if (this.reportRange === 'previous_month') {
        this.historyRecordDateRange[0] = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        this.historyRecordDateRange[1] = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      } else if (this.reportRange === 'previous_year') {
        this.historyRecordDateRange[0] = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
        this.historyRecordDateRange[1] = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
      } else if (this.reportRange === 'past_30_days') {
        this.historyRecordDateRange[0] = moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD');
        this.historyRecordDateRange[1] = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
      } else {
        console.log('custom_range');
      }
    }
  },
};
</script>
